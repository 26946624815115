<template>
  <v-container>
    <v-row v-if="baby && baby.title">
      <v-col cols="12" sm="5">
        <v-card>
          <v-img :src="base_url+baby.img"></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7">
        <v-card>
          <v-card-title>
            <h3>{{baby.title}}</h3>
          </v-card-title>
          <v-card-text v-html="baby.text"></v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("request", {
      state: "baby",
      method: "POST",
      url: "getPhotoBornH2p/"+this.$route.params.name
    });
  },
  computed: {
    baby() {
      return this.$store.state.baby
    },
    base_url() {
      return this.$store.state.base_url
    }
  },
  watch: {
    '$route.params.name': function () {
      this.$store.dispatch("request", {
        state: "baby",
        method: "POST",
        url: "getPhotoBornH2p/"+this.$route.params.name
      });
    }
  }
}
</script>

<style>

</style>